import bindAll from 'lodash.bindall';
import PropTypes from 'prop-types';
import React from 'react';
import VM from 'scratch-vm';
import { connect } from 'react-redux';
import analytics from '../lib/analytics';

import ControlsComponent from '../components/controls/controls.jsx';

class Controls extends React.Component {
    constructor(props) {
        super(props);
        bindAll(this, [
            'handleBluetoothClick',
            'handleGreenFlagClick',
            'handleStopAllClick',
        ]);
    }

    handleBluetoothClick(e) {
        e.preventDefault();

        analytics.event({
            category: 'button',
            action: 'Start',
            label: localStorage.getItem('exampleId') ? localStorage.getItem('exampleId') : ''
        });
        this.props.vm.bluetooth();
    }

    handleGreenFlagClick(e) {
        e.preventDefault();
        analytics.event({
            category: 'button',
            action: 'Start',
            label: localStorage.getItem('exampleId') ? localStorage.getItem('exampleId') : ''
        });
        if (e.shiftKey) {
            this.props.vm.setTurboMode(!this.props.turbo);
        } else {
            if (!this.props.isStarted) {
                this.props.vm.start();
            }
            this.props.vm.greenFlag();
        }
    }
    handleStopAllClick(e) {
        e.preventDefault();
        analytics.event({
            category: 'button',
            action: 'Stop',
            label: localStorage.getItem('exampleId') ? localStorage.getItem('exampleId') : ''
        });
        this.props.vm.stopAll();
    }
    render() {
        const {
            vm, // eslint-disable-line no-unused-vars
            isStarted, // eslint-disable-line no-unused-vars
            projectRunning,
            turbo,
            recording,
            ...props
        } = this.props;
        return (
            <ControlsComponent
                {...props}
                active={projectRunning}
                turbo={turbo}
                onBluetoothClick={this.handleBluetoothClick}
                onGreenFlagClick={this.handleGreenFlagClick}
                onStopAllClick={this.handleStopAllClick}
                onRecordClick={() => {
                    if (recording) {
                        this.props.vm.stopRecording();
                        this.props.vm.downloadRecording();
                    } else {
                        this.props.vm.startRecording();
                    }
                }}
                onStopRecordClick={() => {
                    this.props.vm.stopRecording()
                }}
                onDownloadClick={() => {
                    this.props.vm.downloadRecording()
                }}
                recording={recording}
            />
        );
    }
}

Controls.propTypes = {
    isStarted: PropTypes.bool.isRequired,
    projectRunning: PropTypes.bool.isRequired,
    turbo: PropTypes.bool.isRequired,
    recording: PropTypes.bool.isRequired,
    vm: PropTypes.instanceOf(VM)
};

const mapStateToProps = state => ({
    isStarted: state.scratchGui.vmStatus.running,
    projectRunning: state.scratchGui.vmStatus.running,
    recording: state.scratchGui.vmStatus.recording,
    turbo: state.scratchGui.vmStatus.turbo
});
// no-op function to prevent dispatch prop being passed to component
const mapDispatchToProps = () => ({});

export default connect(mapStateToProps, mapDispatchToProps)(Controls);
