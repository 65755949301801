exports = module.exports = require("../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, "/* #E5F0FF */\n/* #E0F5F4 */\n/* #D9E3F2 */\n/* 90% transparent version of motion-primary */\n/* #FFFFFF */\n/* 25% transparent version of ui-white */\n/* 25% transparent version of ui-white */\n/* 25% transparent version of ui-white */\n/* 15% transparent version of black */\n/* #575E75 */\n/* #4C97FF */\n/* #3373CC */\n/* 35% transparent version of motion-primary */\n/* 15% transparent version of motion-primary */\n/* #FF661A */\n/* #E64D00 */\n/* #CF63CF */\n/* #BD42BD */\n/* #FFAB19 */\n/* #FF8C1A */\n/* #0FBD8C */\n/* #0FBD8C */\n/* #FF8C1A */\n/* #FFB366 */\n/* #FF8C1A */\n/* #0FBD8C */\n/* #0B8E69 */\n/* 35% transparent version of extensions-primary */\n/* opaque version of extensions-transparent, on white bg */\n/* lighter than motion-primary */\n.share-button_share-button_Nxxf0 {\n    background: hsla(30, 100%, 55%, 1);\n}\n.share-button_share-button-is-shared_1tjKq {\n    background: hsla(0, 0%, 0%, 0.15);\n    cursor: default;\n}\n", ""]);

// exports
exports.locals = {
	"share-button": "share-button_share-button_Nxxf0",
	"shareButton": "share-button_share-button_Nxxf0",
	"share-button-is-shared": "share-button_share-button-is-shared_1tjKq",
	"shareButtonIsShared": "share-button_share-button-is-shared_1tjKq"
};