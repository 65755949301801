import classNames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';

import bluetoothIcon from './icon--bluetooth.svg';
import styles from './bluetooth.css';

const BluetoothComponent = function (props) {
    const {
        active,
        className,
        onClick,
        title,
        ...componentProps
    } = props;
    return (
        <img
            id="bluetooth-button"
            className={classNames(
                className,
                styles.bluetooth,
                {
                    [styles.isActive]: active
                }
            )}
            draggable={false}
            src={bluetoothIcon}
            title={title}
            onClick={onClick}
            {...componentProps}
        />
    );
};
BluetoothComponent.propTypes = {
    active: PropTypes.bool,
    className: PropTypes.string,
    onClick: PropTypes.func.isRequired,
    title: PropTypes.string
};
BluetoothComponent.defaultProps = {
    active: false,
    title: 'Go'
};
export default BluetoothComponent;
