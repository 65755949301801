exports = module.exports = require("../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, "/* #E5F0FF */\n/* #E0F5F4 */\n/* #D9E3F2 */\n/* 90% transparent version of motion-primary */\n/* #FFFFFF */\n/* 25% transparent version of ui-white */\n/* 25% transparent version of ui-white */\n/* 25% transparent version of ui-white */\n/* 15% transparent version of black */\n/* #575E75 */\n/* #4C97FF */\n/* #3373CC */\n/* 35% transparent version of motion-primary */\n/* 15% transparent version of motion-primary */\n/* #FF661A */\n/* #E64D00 */\n/* #CF63CF */\n/* #BD42BD */\n/* #FFAB19 */\n/* #FF8C1A */\n/* #0FBD8C */\n/* #0FBD8C */\n/* #FF8C1A */\n/* #FFB366 */\n/* #FF8C1A */\n/* #0FBD8C */\n/* #0B8E69 */\n/* 35% transparent version of extensions-primary */\n/* opaque version of extensions-transparent, on white bg */\n/* lighter than motion-primary */\n.turbo-mode_turbo-container_2ZNcq {\n    display: -webkit-box;\n    display: -webkit-flex;\n    display: -ms-flexbox;\n    display: flex;\n    -webkit-box-align: center;\n    -webkit-align-items: center;\n        -ms-flex-align: center;\n            align-items: center;\n    padding: 0.25rem;\n    -webkit-user-select: none;\n       -moz-user-select: none;\n        -ms-user-select: none;\n            user-select: none;\n}\n.turbo-mode_turbo-icon_fjr8U {\n    margin: 0.25rem;\n}\n.turbo-mode_turbo-label_-1oMs {\n    font-size: 0.625rem;\n    font-weight: bold;\n    font-family: \"Helvetica Neue\", Helvetica, Arial, sans-serif;\n    color: hsla(38, 100%, 55%, 1);\n    white-space: nowrap;\n}\n", ""]);

// exports
exports.locals = {
	"turbo-container": "turbo-mode_turbo-container_2ZNcq",
	"turboContainer": "turbo-mode_turbo-container_2ZNcq",
	"turbo-icon": "turbo-mode_turbo-icon_fjr8U",
	"turboIcon": "turbo-mode_turbo-icon_fjr8U",
	"turbo-label": "turbo-mode_turbo-label_-1oMs",
	"turboLabel": "turbo-mode_turbo-label_-1oMs"
};