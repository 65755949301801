import classNames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';

import styles from './console.css';

const ConsoleComponent = function (props) {
    const {
        active,
        className,
        //onClick,
        title,
        ...componentProps
    } = props;
    return (
        /*<img
            className={classNames(
                className,
                styles.console,
                {
                    [styles.isActive]: active
                }
            )}
            draggable={false}
            src={consoleIcon}
            title={title}
            onClick={onClick}
            {...componentProps}
        />*/
        <div
            className={classNames(
                className,
                styles.console,
                {
                    [styles.isActive]: active
                }
            )}
            draggable={false}
            title={title}
            {...componentProps}
        >
            <ul id="value-report-list">

            </ul>
        </div>
    );
};
ConsoleComponent.propTypes = {
    active: PropTypes.bool,
    className: PropTypes.string,
    //onClick: PropTypes.func.isRequired,
    title: PropTypes.string
};
ConsoleComponent.defaultProps = {
    active: false,
    title: 'Go'
};
export default ConsoleComponent;
